import { useDisclosure } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { useHarmony } from '@hooks/use-harmony';
import InnerHtml from '@designs/primitives/common/inner-html';
import { IThemeProps } from '@type/common';
import React from 'react';
import { BlockVStack } from 'src/legacy/components/blockui';
import { TermDrawerProps } from 'src/legacy/components/common/custom-term-drawer';
import DropdownAccordion from 'src/legacy/components/common/dropdown-accordion';
import FullSizeDrawerContent from 'src/legacy/components/common/full-size-drawer-content';
import BodyText from 'src/legacy/components/common/text/body-text';

const TermDrawerC: React.FC<TermDrawerProps> = ({ term, onClose, historyIndex = 0, setHistoryIndex = () => {} }) => {
    const { theme } = useHarmony();
    const { isOpen, onClose: closeAccordion, onToggle } = useDisclosure();

    return (
        <>
            {term && (
                <FullSizeDrawerContent
                    title={term.title || ''}
                    headerButton={{
                        type: 'close',
                        onClose: onClose,
                    }}
                >
                    <BlockVStack padding="16px" spacing="16px">
                        <DropdownAccordion open={isOpen} onToggle={onToggle} trigger={term.combinedHistoricalDates[historyIndex]} padding={'0 12px 0 0'}>
                            {term.combinedHistoricalDates.map((item, i) => (
                                <TermItem
                                    key={i}
                                    theme={theme}
                                    role="button"
                                    onClick={() => {
                                        setHistoryIndex(i);
                                        closeAccordion();
                                    }}
                                >
                                    <BodyText size="17px">{item}</BodyText>
                                </TermItem>
                            ))}
                        </DropdownAccordion>
                        <StyledInnerHtml content={term.combinedHistoricalContents[historyIndex]} theme={theme} />
                    </BlockVStack>
                </FullSizeDrawerContent>
            )}
        </>
    );
};

export default TermDrawerC;

const TermItem = styled.div`
    cursor: pointer;
    height: 44px;
    padding: 12px 16px;
`;

const StyledInnerHtml = styled(InnerHtml)<IThemeProps>`
    letter-spacing: -0.01em;
    white-space: pre-wrap;
    word-break: break-all;
    font-size: 14px;
    line-height: 22px;
    color: ${({ theme }) => theme.gray[600]};
    font-family: ${({ theme }) => theme.font.primary};
`;
