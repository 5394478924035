import TermDrawer from '@legacy/designs/term-drawer';
import Term from '@models/term';
import { Dispatch, SetStateAction, useState } from 'react';
import CustomDrawer from './custom-drawer';

export interface TermDrawerProps {
    term: Term | undefined;
    open: boolean;
    onClose: () => void;
    historyIndex?: number;
    setHistoryIndex?: Dispatch<SetStateAction<number>>;
}

const CustomTermDrawer: React.FC<TermDrawerProps> = ({ term, open, onClose }) => {
    const [historyIndex, setHistoryIndex] = useState<number>(0);

    return (
        <CustomDrawer anchor="bottom" open={open} onClose={onClose}>
            <TermDrawer term={term} open={open} onClose={onClose} historyIndex={historyIndex} setHistoryIndex={setHistoryIndex} />
        </CustomDrawer>
    );
};

export default CustomTermDrawer;
