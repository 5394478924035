import FullSizeDrawerContent from 'src/legacy/components/common/full-size-drawer-content';
import InnerHtml from '@designs/primitives/common/inner-html';
import SelectAccordion from '@legacy/designs/select-accordion';
import { TermDrawerProps } from 'src/legacy/components/common/custom-term-drawer';
import styled from '@emotion/styled';
import { HorizPadContainer } from '@styles/styled-components';
import { FontWeight } from '@type/theme';
import React from 'react';

const TermDrawerA: React.FC<TermDrawerProps> = ({ term, onClose, historyIndex = 0, setHistoryIndex = () => {} }) => {
    return (
        <>
            {term && (
                <FullSizeDrawerContent
                    title={term.title || ''}
                    headerButton={{
                        type: 'close',
                        onClose: onClose,
                    }}
                >
                    <HorizPadContainer>
                        <SelectAccordion trigger={term.combinedHistoricalDates[historyIndex]} items={term.combinedHistoricalDates} onSelect={(index) => setHistoryIndex(index)} />
                    </HorizPadContainer>
                    <HorizPadContainer>
                        <StyledInnerHtml content={term.combinedHistoricalContents[historyIndex]} weight={'thin'} />
                    </HorizPadContainer>
                </FullSizeDrawerContent>
            )}
        </>
    );
};

export default TermDrawerA;

const StyledInnerHtml = styled(InnerHtml)<{ weight?: FontWeight }>`
    letter-spacing: -0.03em;
    white-space: pre-wrap;
    word-break: break-all;
    font-size: 0.87rem;
    font-weight: ${({ weight }) => weight && weight};
    margin-top: 32px;
    line-height: 26px;
`;
