import { TermDrawerProps } from 'src/legacy/components/common/custom-term-drawer';
import FullSizeDrawerContent from 'src/legacy/components/common/full-size-drawer-content';
import SelectAccordion from '@legacy/designs/select-accordion';
import InnerHtml from '@designs/primitives/common/inner-html';
import styled from '@emotion/styled';
import { useHarmony } from '@hooks/use-harmony';
import { PaddedContainer } from '@styles/styled-components';
import { IThemeProps } from '@type/common';
import ThemeUtils from '@utils/theme.utils';

const TermDrawerB: React.FC<TermDrawerProps> = ({ term, onClose, historyIndex = 0, setHistoryIndex = () => {} }) => {
    const { theme, designSettings } = useHarmony();
    return (
        <>
            {term && (
                <FullSizeDrawerContent
                    title={term.title || ''}
                    headerButton={{
                        type: 'close',
                        onClose: onClose,
                    }}
                >
                    <PaddedContainer padding="24px 16px 24px 16px">
                        <SelectAccordion trigger={term.combinedHistoricalDates[historyIndex]} items={term.combinedHistoricalDates} onSelect={(index) => setHistoryIndex(index)} />
                    </PaddedContainer>
                    <PaddedContainer padding="8px 16px 16px 16px">
                        <StyledInnerHtml theme={theme} fontSize={designSettings?.getLegacyFontSize('15px') || '15px'} content={term.combinedHistoricalContents[historyIndex]} />
                    </PaddedContainer>
                </FullSizeDrawerContent>
            )}
        </>
    );
};

export default TermDrawerB;

const StyledInnerHtml = styled(InnerHtml)<IThemeProps>`
    ${({ theme, fontSize }) => `
        white-space: pre-wrap;
        word-break: break-all;
        line-height: 26px;
        letter-spacing: -0.03em;
        font-family: ${ThemeUtils.getFont('regular', 'default', theme)};
        font-size: ${fontSize};
        line-height: 24px;
        color: ${theme.gray[700]};
    `}
`;
