import { useDesignComponents } from '@hooks/use-design-components';

import { TermDrawerProps } from 'src/legacy/components/common/custom-term-drawer';
import { Design } from '@type/harmony-config';
import React from 'react';
import TermDrawerA from './designA/term-drawer';
import TermDrawerB from './designB/term-drawer';
import TermDrawerC from './designC/term-drawer';

const TermDrawer: React.FC<TermDrawerProps> = ({ ...props }) => {
    const Component = useDesignComponents<typeof TermDrawerA | typeof TermDrawerB | typeof TermDrawerC>({
        [Design.DesignA]: TermDrawerA,
        [Design.DesignB]: TermDrawerB,
        [Design.DesignC]: TermDrawerC,
    });

    return <Component {...props} />;
};

export default TermDrawer;
